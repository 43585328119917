import { gql } from '@apollo/client';

const GET_ROLES = gql`
  query RolesList {
    rolesList {
      count
      roles {
        title
        id
        roleKey
      }
    }
  }
`;

export { GET_ROLES };
