import React, { createContext, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { getUser } from 'utils/UserDetails';
import { GET_USER_DETAIL_BY_ID } from 'pages/Profile/ProfileGraphql';

export const UserDetailsContext = createContext('');

const UserDetailProvider = ({ children }) => {
  const user = getUser();
  const { data } = useQuery(GET_USER_DETAIL_BY_ID, {
    variables: {
      userId: user.id
    }
  });

  const userData = data?.getUserPersonalDetailById ? data?.getUserPersonalDetailById : {};

  let context = useMemo(() => userData, [userData]);

  return <UserDetailsContext.Provider value={context}>{children}</UserDetailsContext.Provider>;
};

export default UserDetailProvider;
