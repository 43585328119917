import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import {
  AGENT_KEY,
  INVESTOR_KEY,
  MORTGAGE_ADMIN,
  PRINCIPAL_BROKER,
  OPPORTUNITY_KEY,
  APPROVED_KEY,
  NOT_REQUESTED,
  PENDING_KEY,
  REJECTED_KEY,
  CONNECTED
} from 'constant';

import dashboardIconBlue from 'assets/icons/dashboard-blue.svg';
import opportunityIconBlue from 'assets/icons/opportunity-blue.svg';
import proposalIconBlue from 'assets/icons/proposal-blue.svg';
import agentIconBlue from 'assets/icons/agents-blue.svg';
import investorsIconBlue from 'assets/icons/investors-blue.svg';
import lenderIconBlue from 'assets/icons/lender-finder-blue.svg';
import userIconBlue from 'assets/icons/user-icon-blue.svg';
import approvalsIconBlue from 'assets/icons/approvals-blue.svg';
import auditIconBlue from 'assets/icons/audit-icon-blue.svg';

const dashboard = {
  menuName: 'Dashboard',
  menuIcon: dashboardIconBlue,
  path: 'dashboard'
};

const INVESTOR_MENU = [
  {
    menuName: 'Find Opportunity',
    menuIcon: opportunityIconBlue,
    path: 'find-opportunity'
  },
  {
    menuName: 'Portfolio',
    menuIcon: proposalIconBlue,
    path: 'proposals/inprogress'
  }
  // {
  //   menuName: 'Find Broker',
  //   menuIcon: agentIconBlue,
  //   path: 'find-broker'
  // }
];

const MA_MENU = [
  {
    menuName: 'Find Opportunity',
    menuIcon: opportunityIconBlue,
    path: 'find-opportunity'
  },
  {
    menuName: 'Portfolio',
    menuIcon: proposalIconBlue,
    path: 'proposals/inprogress'
  },
  {
    menuName: 'My Investors',
    menuIcon: investorsIconBlue,
    path: 'investors'
  },
  {
    menuName: 'Lender Finder',
    menuIcon: lenderIconBlue,
    path: 'lender-finder'
  }
  // {
  //   menuName: 'Find Broker',
  //   menuIcon: agentIconBlue,
  //   path: 'find-broker'
  // }
];

const AGENT_MENU = [
  {
    menuName: 'My Opportunity',
    menuIcon: opportunityIconBlue,
    path: 'opportunity/inprogress',
    key: OPPORTUNITY_KEY
  },
  {
    menuName: 'Lender Finder',
    menuIcon: lenderIconBlue,
    path: 'lender-finder'
  }
];

const PB_MENU = [
  {
    menuName: 'My Opportunity',
    menuIcon: opportunityIconBlue,
    path: 'opportunity/inprogress',
    key: OPPORTUNITY_KEY
  },
  // {
  //   menuName: 'Proposals',
  //   menuIcon: proposalIconBlue,
  //   path: 'proposals'
  // },
  {
    menuName: 'My Agents',
    menuIcon: agentIconBlue,
    path: 'agents'
  },
  {
    menuName: 'Lender Finder',
    menuIcon: lenderIconBlue,
    path: 'lender-finder'
  }
];

const ADMIN = [
  {
    menuName: 'Opportunity',
    menuIcon: opportunityIconBlue,
    path: 'opportunity/inprogress'
  },
  {
    menuName: 'User',
    menuIcon: userIconBlue,
    path: 'users/investor'
  },
  {
    menuName: 'Approvals',
    menuIcon: approvalsIconBlue,
    path: 'approvals/investor'
  },
  {
    menuName: 'Audit',
    menuIcon: auditIconBlue,
    path: 'audit'
  }
];

const getSidebarMenu = (id, userDetail) => {
  const { approvedStatus: status, licensedFsra, requestStatus } = userDetail;
  if (!id || !status) return [];
  switch (id) {
    case INVESTOR_KEY:
      if (licensedFsra && status === APPROVED_KEY) {
        return [{ ...dashboard }, ...INVESTOR_MENU];
      }
      // if (status === REJECTED_KEY) {
      //   return [
      //     { ...dashboard },
      //     {
      //       menuName: 'Find Opportunity',
      //       menuIcon: opportunityIconBlue,
      //       path: 'find-opportunity'
      //     }
      //   ];
      // }
      return status === APPROVED_KEY && requestStatus === CONNECTED
        ? [{ ...dashboard }, ...INVESTOR_MENU]
        : [
            { ...dashboard },
            {
              menuName: 'Find Opportunity',
              menuIcon: opportunityIconBlue,
              path: 'find-opportunity'
            }
          ];

    case AGENT_KEY:
      if (status === REJECTED_KEY) {
        return [
          { ...dashboard },
          {
            menuName: 'Lender Finder',
            menuIcon: lenderIconBlue,
            path: 'lender-finder'
          }
        ];
      }
      return status === APPROVED_KEY &&
        requestStatus !== NOT_REQUESTED &&
        requestStatus !== PENDING_KEY
        ? [{ ...dashboard }, ...AGENT_MENU]
        : [
            { ...dashboard },
            {
              menuName: 'Lender Finder',
              menuIcon: lenderIconBlue,
              path: 'lender-finder'
            }
          ];
    case MORTGAGE_ADMIN:
      return status === APPROVED_KEY
        ? [{ ...dashboard }, ...MA_MENU]
        : [
            { ...dashboard },
            {
              menuName: 'Find Opportunity',
              menuIcon: opportunityIconBlue,
              path: 'find-opportunity'
            }
          ];
    case PRINCIPAL_BROKER:
      return status === APPROVED_KEY
        ? [{ ...dashboard }, ...PB_MENU]
        : [
            { ...dashboard },
            {
              menuName: 'Lender Finder',
              menuIcon: lenderIconBlue,
              path: 'lender-finder'
            }
          ];
    default:
      return [{ ...dashboard }, ...ADMIN];
  }
};

export { getSidebarMenu };
