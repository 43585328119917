import { gql } from '@apollo/client';

const GET_NOTIFICATION = gql`
  query getNotifications($offset: Int, $limit: Int, $isRead: Boolean, $isOpen: Boolean) {
    getNotifications(offset: $offset, limit: $limit, isRead: $isRead, isOpen: $isOpen)
  }
`;

const UPDATE_NOTIFICATION_STATUS = gql`
  mutation UpdateNotificationStatus($isRead: Boolean!, $isOpen: Boolean!) {
    updateNotificationStatus(isRead: $isRead, isOpen: $isOpen)
  }
`;

const UDATE_NOTIFICATION_STATUS_BY_ID = gql`
  mutation UpdateNotificationStatusById($updateNotificationStatusByIdId: Int!, $isRead: Boolean!) {
    updateNotificationStatusById(id: $updateNotificationStatusByIdId, isRead: $isRead)
  }
`;

const NOTIFICATION_SUBSCRIPTION = gql`
  subscription notificationSubscription($userId: Int!) {
    notificationSubscription(userId: $userId)
  }
`;

const OPPORTUNITY_MESSAGE_NOTIFICATION_SUBSCRIPTION = gql`
  subscription opportunityMessageNotificationSubscription($userId: Int!) {
    opportunityMessageNotificationSubscription(userId: $userId)
  }
`;

export {
  GET_NOTIFICATION,
  UPDATE_NOTIFICATION_STATUS,
  NOTIFICATION_SUBSCRIPTION,
  UDATE_NOTIFICATION_STATUS_BY_ID,
  OPPORTUNITY_MESSAGE_NOTIFICATION_SUBSCRIPTION
};
