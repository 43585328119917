import React, { createContext, useState, useMemo, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ROLES } from './RolesGraphQL';
import { getUser } from 'utils/UserDetails';
import { find, isEmpty } from 'lodash';

export const RoleContext = createContext('');

const RoleProvider = ({ children }) => {
  const [roleKey, setRoleKey] = useState(null);
  const [roleTitle, setRoleTitle] = useState('');
  const { data, error, refetch, loading } = useQuery(GET_ROLES);
  const user = getUser();

  const onFetchRole = async () => {
    const fetchUser = getUser();
    try {
      const { data: result } = await refetch();
      const {
        rolesList: { roles }
      } = result;
      const roleKey = find(roles, (item) => item.id === parseInt(fetchUser.roleId));
      setRoleTitle(roleKey.title);
      setRoleKey(roleKey.roleKey);
    } catch (error) {}
  };

  let context = useMemo(
    () => ({ roleTitle, data, roleKey, onFetchRole, loading }),
    [data, roleTitle, roleKey]
  );

  useEffect(() => {
    if (data && !isEmpty(user)) {
      const {
        rolesList: { roles }
      } = data;
      const roleKey = find(roles, (item) => item.id === parseInt(user.roleId));
      setRoleTitle(roleKey.title);
      setRoleKey(roleKey.roleKey);
    }
  }, [data]);

  if (error) {
    return error.message;
  }

  return <RoleContext.Provider value={context}>{children}</RoleContext.Provider>;
};

export default RoleProvider;
