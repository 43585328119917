import React, { useContext, useEffect, useRef, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useMediaQuery } from 'react-responsive';
import {
  removeApplicantId,
  removeOpportunityId
} from 'pages/Opportunity/components/CreateOpportunity/Helper';
import { useLocation, useNavigate } from 'react-router-dom';
import ImportFIlelogixModal from './ImportFIlelogixModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { isMobileBrakePoint } from 'utils/responsiveBrakePoints';
import { OpportunityContentsContext } from 'pages/Opportunity/components/CreateOpportunity/context';
import { UserDetailsContext } from 'context/UserDetail';

const CreateOppMenu = () => {
  const [isOpen, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const isMobile = useMediaQuery({ query: `(${isMobileBrakePoint})` });
  const { pathname } = useLocation();
  const { onResetContents } = useContext(OpportunityContentsContext);

  const userDetails = useContext(UserDetailsContext);

  const navigate = useNavigate();

  const myRef = useRef();
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const handleClickOutside = (e) => {
    if (myRef.current && !myRef.current.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const onRouteChange = () => {
    onResetContents();
    removeOpportunityId();
    removeApplicantId();
    setOpen(false);
    if (pathname === '/create-opportunity') {
      window.location.reload();
    } else {
      navigate('/create-opportunity');
    }
  };

  const onToggleModal = () => {
    setModal((pre) => !pre);
    setOpen(false);
  };

  return (
    <div>
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle color="primary" size="sm" className={`${isMobile ? 'font-size-12' : ''}`}>
          Create Opportunity
        </DropdownToggle>
        <DropdownMenu className="user-profile border-0">
          {userDetails && userDetails.isFilofixPresent && (
            <DropdownItem onClick={onToggleModal}>
              <div className="d-flex justify-content-between align-items-center">
                Import from Filogix
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </DropdownItem>
          )}
          <DropdownItem onClick={onRouteChange}>
            <div className="d-flex justify-content-between align-items-center">
              Create Manually
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      {modal && <ImportFIlelogixModal isOpen={modal} toggle={onToggleModal} />}
    </div>
  );
};

export default CreateOppMenu;
