import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { routes } from './routes';
import { store } from 'store';
import { Provider } from 'react-redux';
import './styles/style.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-number-input/style.css';
import { getUser } from 'utils/UserDetails';

const App = () => {
  const user = getUser();

  return (
    <div className="App">
      <Provider store={store}>
        <RouterProvider router={createBrowserRouter(routes(user))} />
        <ToastContainer position="top-right" autoClose={3000} />
      </Provider>
    </div>
  );
};

export default App;
