import React, { useContext, useRef, useEffect, memo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { getSidebarMenu } from './Menu';
import { RoleContext } from 'context/Role';
import { UserDetailsContext } from 'context/UserDetail';
import openFundLogo from 'assets/logos/logo.svg';
import { useMediaQuery } from 'react-responsive';
import { isTabBrakePoint } from 'utils/responsiveBrakePoints';

const Sidebar = ({ isOpen: miniBar, onClickMenu }) => {
  const ref = useRef();
  const { pathname } = useLocation();
  const roles = useContext(RoleContext);
  const userDetails = useContext(UserDetailsContext);
  const isTab = useMediaQuery({ query: `(${isTabBrakePoint})` });

  const handleClickOutside = (event) => {
    if (ref?.current && !ref.current.contains(event.target) && miniBar) {
      onClickMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mouseover', handleClickOutside, false);
    return () => {
      document.removeEventListener('mouseover', handleClickOutside);
    };
  }, [handleClickOutside]);

  const getActiveURL = (menu) => {
    const path = `/${menu.path}`;
    const splitPath = path.split('/');
    const splitPathname = pathname.split('/');
    if (splitPathname.length === 5) {
      const subPath = splitPathname[1];
      return menu.path.includes(subPath) ? 'opp-side-menu active' : 'opp-side-menu';
    }
    if (splitPath.length === 3) {
      const subPath = splitPath[1];
      return pathname.includes(subPath) ? 'opp-side-menu active' : 'opp-side-menu';
    }
    return path === pathname ? 'opp-side-menu active' : 'opp-side-menu';
  };

  const renderNavLinks = (menu) => {
    return (
      <NavLink to={`/${menu.path}`} key={menu.path} className={getActiveURL(menu)}>
        <img src={menu.menuIcon} alt="menu-icons" height="20" width="20" className="me-3" />
        <span className="icon-text semi-bold">{menu.menuName}</span>
      </NavLink>
    );
  };

  const isTabAvailable = () => {
    if (isTab) {
      return miniBar === true ? '200px' : '0px';
    } else {
      return miniBar === true ? '200px' : '48px';
    }
  };

  return (
    <div
      ref={ref}
      style={{ width: isTabAvailable() }}
      id="mySidebar"
      className="sidebar"
      // onMouser
      onMouseOver={(e) => onClickMenu(true, e)}
      // onMouseEnter={() => onClickMenu(true)}
      // onMouseLeave={() => onClickMenu(false)}
    >
      <NavLink to="/dashboard" className="user-select-none logo-header">
        <img src={openFundLogo} alt="openFundLogo" height="22" width="22" className="me-3" />
        <span className="icon-text">
          <span className="text-dark fw-light">Open</span>
          <span className="semi-bold text-dark">fund</span>
        </span>
      </NavLink>
      {getSidebarMenu(roles.roleTitle, userDetails).map((menu) => {
        return renderNavLinks(menu);
      })}
    </div>
  );
};

export default memo(Sidebar);
