import React, { Fragment, useEffect, useState } from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import { Label } from 'reactstrap';
import Autocomplete from 'react-google-autocomplete';

const StreetAddress = ({ name, label, isError = true, fillAddress, ...rest }) => {
  const [hasError, setHasError] = useState(false);
  const { values, errors, touched, setFieldValue, handleBlur, handleChange } = useFormikContext();

  useEffect(() => {
    const setError = checkIfError();
    setHasError(setError);
  }, [errors, touched]);

  const checkIfError = () => {
    return errors[name] && touched[name];
  };

  const getPostalCode = (name) => {
    const splitName = name.split(' ');
    if (splitName.length === 2) {
      return `${splitName[0]} ${splitName[1]}`;
    }
    return name;
  };

  const onPlaceSelected = (place) => {
    const str = place.formatted_address;
    const splitStreetAdd = str.split(',');
    setFieldValue(name, splitStreetAdd[0]);

    if (fillAddress) {
      const { country, province, city, postalCode } = fillAddress;
      let newCity = null;
      let containSubLocality = false;
      let newPostalCode = null;
      let newProvince = null;
      for (const component of place.address_components) {
        // @ts-ignore remove once typings fixed
        const componentType = component.types[0];

        switch (componentType) {
          case 'postal_code':
            let postalCodeDigit = getPostalCode(`${component.long_name}`);
            if (postalCode) newPostalCode = postalCodeDigit;
            break;
          case 'sublocality_level_1':
            if (city) {
              containSubLocality = true;
              newCity = component.long_name;
            }
            break;

          case 'locality':
            let locality = component.long_name;
            if (city && !containSubLocality) {
              newCity = locality;
            }
            break;
          case 'administrative_area_level_1':
            let stateName = component.long_name;
            if (province) newProvince = stateName;
            break;

          case 'country':
            let countryName = component.long_name;
            if (country) {
              setTimeout(() => {
                setFieldValue(country, countryName);
              }, 100);
            }
            break;
        }
      }

      if (newPostalCode) {
        setTimeout(() => {
          setFieldValue(postalCode, newPostalCode);
        }, 0);
      }
      if (newProvince) {
        setTimeout(() => {
          setFieldValue(province, newProvince);
        }, 35);
      }
      if (newCity) {
        setTimeout(() => {
          setFieldValue(city, newCity);
        }, 55);
      }
    }
  };

  const onCaptureKey = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <Fragment>
      <Label>{label}</Label>
      <Autocomplete
        className={`form-control-lg form-control ${hasError ? 'is-invalid' : ''}`}
        onBlur={handleBlur}
        onChange={handleChange}
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        onPlaceSelected={onPlaceSelected}
        name={name}
        defaultValue={values?.[name] ? values?.[name] : ''}
        placeholder=""
        options={{
          fields: ['geometry', 'formatted_address', 'address_components'],
          types: ['address'],
          componentRestrictions: { country: 'CA' }
        }}
        {...rest}
        value={values?.[name]}
        id="street-add-2"
        onKeyDown={onCaptureKey}
      />
      {isError && (
        <ErrorMessage
          name={name}
          render={(msg) => {
            return <div className="text-danger font-size-14">{msg}</div>;
          }}
        />
      )}
    </Fragment>
  );
};

export default StreetAddress;
