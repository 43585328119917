import React, { useMemo } from 'react';
import Dropzone from 'react-dropzone';
import { Button } from 'reactstrap';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '40px 20px',
  borderColor: '#707070',
  backgroundColor: '#F8F8F8',
  color: '#81838A',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  fontSize: '14px'
};

const FileDragDrop = ({
  text = 'Drag an file here or',
  uploadFile,
  isError,
  acceptFile = { 'image/*': [] }
}) => {
  const style = useMemo(() => ({
    ...baseStyle
  }));

  const onUploadFile = (file) => {
    if (!file.length) return;
    uploadFile(file);
  };
  const isOutline = (isDragActive) => {
    if (isDragActive) {
      return 'on-drag';
    } else if (isError) {
      return 'dropzone-error';
    } else {
      return 'dropzone-outline';
    }
  };

  return (
    <Dropzone onDrop={onUploadFile} noClick={true} accept={acceptFile}>
      {({ getRootProps, getInputProps, open, isDragActive }) => (
        <div {...getRootProps({ style })} className={isOutline(isDragActive)}>
          <input {...getInputProps()} />
          <span className="font-size-16">
            {text}
            <Button onClick={open} color="link" type="button" className="px-1 font-size-14">
              <b className="font-size-16"> Upload a file</b>
            </Button>
          </span>
        </div>
      )}
    </Dropzone>
  );
};

export default FileDragDrop;
