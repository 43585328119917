import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const OpenlendModal = ({
  modal,
  toggle,
  headerTitle,
  children,
  className = 'max-width-650',
  mbClass,
  ...rest
}) => {
  return (
    <Modal isOpen={modal} toggle={toggle} centered className={`${className}`}>
      {headerTitle ? <ModalHeader>{headerTitle}</ModalHeader> : ''}
      {mbClass ? <div>{children}</div> : <ModalBody>{children}</ModalBody>}
    </Modal>
  );
};

export default OpenlendModal;
