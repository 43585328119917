import React from 'react';
import { ErrorMessage } from 'formik';

const FormikRadioboxField = ({
  field: { name, value, onChange, onBlur },
  form: { touched, errors },
  id,
  label,
  className,
  isError = true,
  ...props
}) => {
  return (
    <div className="radio-btn">
      <input
        className={`mr-1 ${touched[name] && errors[name] ? 'is-invalid' : ''} `}
        name={name}
        id={id}
        type="radio"
        value={id}
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      />
      <label htmlFor={id} className={`${className} ps-2 font-size-14`}>
        {label}
      </label>
      {isError && (
        <ErrorMessage name={name} render={(msg) => <div className="error-message">{msg}</div>} />
      )}
    </div>
  );
};
export default FormikRadioboxField;
