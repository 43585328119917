import { gql } from '@apollo/client';

const GET_FILOGIX_DATA = gql`
  query getFilogixData($filogixId: String!) {
    getFilogixData(filogixId: $filogixId)
  }
`;

const IMPORT_FILOGIX_DATA = gql`
  mutation importFilogixData($filogixId: String!, $streetStaticImage: String!) {
    importFilogixData(filogixId: $filogixId, streetStaticImage: $streetStaticImage)
  }
`;

export { GET_FILOGIX_DATA, IMPORT_FILOGIX_DATA };
