import React, { useState } from 'react';
import OpenlendModal from 'components/OpenlendModal';
import { Field, Formik } from 'formik';
import FormikInputField from 'components/common/Fields/FormikInputField';
import { Button } from 'reactstrap';
import * as Yup from 'yup';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_FILOGIX_DATA, IMPORT_FILOGIX_DATA } from './GraphQL';
import { toast } from 'react-toastify';
import Loader from 'components/Loader';
import { useNavigate } from 'react-router-dom';
import { imageUrlToObj } from 'pages/Opportunity/components/CreateOpportunity/components/PropertyInfo/Helper';
import { UPDATE_FILE } from 'pages/Profile/common/Mutation';
import { getUser } from 'utils/UserDetails';
import CombineErrorMessage from 'components/CombineError';

const ImportFIlelogixModal = ({ isOpen, toggle }) => {
  const [filogixId, setFilogixId] = useState(null);
  const [importLoading, setImportLoading] = useState(false);
  const navigate = useNavigate();
  const [getFileogix, { data, loading, error }] = useLazyQuery(GET_FILOGIX_DATA);
  const [importFilofixData, { loading: importDataLoading }] = useMutation(IMPORT_FILOGIX_DATA);
  const [generatePresignedUrl] = useMutation(UPDATE_FILE);

  const user = getUser();

  const onSubmit = async (values) => {
    try {
      if (data && values.filogixId === filogixId) {
        onImportFilogix();
        return;
      }

      await setFilogixId(values.filogixId);
      await getFileogix({
        variables: {
          filogixId: values.filogixId.toUpperCase()
        }
      });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const filogixData = data?.getFilogixData?.mortgageApplication
    ? data?.getFilogixData?.mortgageApplication
    : null;

  const getSignUrl = async (file) => {
    try {
      const { data } = await generatePresignedUrl({
        variables: {
          fileName: file.name,
          fileType: 'opportunity-street-view',
          userId: user.id
        }
      });
      return data.fileApiPresignedUrl;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onUploadStreetImage = async (file) => {
    try {
      const preSignedUrl = await getSignUrl(file);
      const req = new XMLHttpRequest();
      req.open('PUT', preSignedUrl.signedUrl, true);
      req.send(file);
      return preSignedUrl.sourcePath;
    } catch (err) {
      toast.error(err);
    }
  };

  const getStreetViewUrl = async (subjectProperty) => {
    const { address } = subjectProperty;

    let oppStreetAddress = '';

    if (address.streetNumber) {
      oppStreetAddress += ' ' + address.streetNumber;
    }
    if (address.streetName) {
      oppStreetAddress += ' ' + address.streetName;
    }

    if (address.streetTypeDd) {
      oppStreetAddress += ' ' + address.streetTypeDd;
    }

    const streetStaticImage = `https://maps.googleapis.com/maps/api/streetview?size=1200x500&location=${encodeURIComponent(
      oppStreetAddress + ' ' + address.city + ' ' + `${address.postalFsa} ${address.postalLdu}`
    )}&fov=60&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;

    const file = await imageUrlToObj(streetStaticImage);
    const sourcePath = await onUploadStreetImage(file);
    return sourcePath;
  };

  const onImportFilogix = async () => {
    try {
      setImportLoading(true);
      const { subjectProperty } = filogixData;
      const streetStaticImage = await getStreetViewUrl(subjectProperty);

      const { data: oppData } = await importFilofixData({
        variables: {
          filogixId: filogixId.toUpperCase(),
          streetStaticImage
        }
      });
      if (oppData && oppData.importFilogixData) {
        const { opportunity } = oppData.importFilogixData;
        toggle();
        setImportLoading(false);
        navigate(`/opportunity-detail/${opportunity.id}/edit/publish`, {
          state: {
            filogix: true
          }
        });
      }
    } catch (error) {
      setImportLoading(false);
      console.error('Error adding todo:', error);
    }
  };

  const renderButton = (values) => {
    return data && values.filogixId === filogixId ? (
      <Button color="primary" type="button" size="sm" onClick={onImportFilogix}>
        Import
      </Button>
    ) : (
      <Button color="primary" type="submit" size="sm">
        Submit
      </Button>
    );
  };

  const getLocation = () => {
    if (!data) return;

    const { subjectProperty } = filogixData;
    const { unitNumber, streetNumber, streetName, streetTypeDd, streetDirectionDd, city } =
      subjectProperty.address;

    let location = '';

    if (unitNumber) {
      location = `${unitNumber}-`;
    }

    if (streetNumber) {
      location = `${location}${streetNumber}`;
    }

    if (streetName) {
      location = ` ${location} ${streetName}`;
    }

    if (streetTypeDd) {
      location = ` ${location} ${streetTypeDd}`;
    }

    if (streetDirectionDd) {
      location = ` ${location} ${streetDirectionDd}`;
    }

    location = `${location}, ${city}`;

    return location;
  };

  return (
    <OpenlendModal modal={isOpen} toggle={toggle} headerTitle="Import from Filogix">
      <Formik
        initialValues={{
          filogixId: ''
        }}
        validationSchema={Yup.object({
          filogixId: Yup.string().nullable().trim().required('Please enter Filogix ID')
        })}
        onSubmit={onSubmit}>
        {({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            {error && values.filogixId === filogixId && (
              <CombineErrorMessage>
                The Filogix ID does not exist, please try another.
              </CombineErrorMessage>
            )}
            <Field
              id="filelogix"
              name="filogixId"
              label="Filogix ID"
              type="text"
              component={FormikInputField}
            />
            {data && values.filogixId === filogixId ? (
              <div
                className="p-4 bg-grey my-4"
                style={{ borderRadius: '8px', background: '#f8f8f8' }}>
                <p className="font-size-18 mb-2">Location</p>
                <p className="text-secondary mb-1">{getLocation()}</p>
              </div>
            ) : null}
            <div className="d-flex justify-content-end mt-3">
              <Button
                color="muted"
                type="button"
                outline
                className="me-4"
                size="sm"
                onClick={toggle}>
                Cancel
              </Button>
              {loading || importDataLoading || importLoading ? <Loader /> : renderButton(values)}
            </div>
          </form>
        )}
      </Formik>
    </OpenlendModal>
  );
};

export default ImportFIlelogixModal;
