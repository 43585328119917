import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useLocation } from 'react-router-dom';

const getFieldErrorNames = (formikErrors) => {
  const transformObjectToDotNotation = (obj, prefix = '', result = []) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === 'object') {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

const ErrorScroll = ({ scrollCard, scrollBehavior = { behavior: 'smooth', block: 'center' } }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const field = queryParams.get('field');
  const { submitCount, isValid, errors } = useFormikContext();

  useEffect(() => {
    if (scrollCard) {
      const element = document.querySelector(`div[name='${scrollCard}`);
      if (element) {
        return element.scrollIntoView(scrollBehavior);
      }
    }
    if (isValid) return;

    const fieldErrorNames = getFieldErrorNames(errors);

    if (fieldErrorNames.length <= 0) return;

    const fieldIndex = fieldErrorNames.findIndex((val) => {
      return val === field;
    });

    const errorName = fieldIndex > -1 ? fieldErrorNames[fieldIndex] : fieldErrorNames[0];

    const element = document.querySelector(`input[name='${errorName}']`);
    if (element) {
      return element.scrollIntoView(scrollBehavior);
    }

    const selectElement = document.querySelector(`select[name='${errorName}']`);
    if (selectElement) {
      return selectElement.scrollIntoView(scrollBehavior);
    }
    const textAreaElement = document.querySelector(`textarea[name='${errorName}']`);
    if (textAreaElement) {
      return textAreaElement.scrollIntoView(scrollBehavior);
    }
    // Scroll to first known error into view

    // Formik doesn't (yet) provide a callback for a client-failed submission,
    // thus why this is implemented through a hook that listens to changes on
    // the submit count.
  }, [submitCount, field, scrollCard]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default ErrorScroll;
