import React from 'react';
import { Button, Spinner } from 'reactstrap';

const Loader = (props, { size }) => {
  return (
    <Button type="button" size={size ? size : ''} color="muted" disabled {...props}>
      <Spinner size="sm" className="me-1" /> Loading
    </Button>
  );
};

export default Loader;
