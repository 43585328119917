import React from 'react';
import { Input, Label } from 'reactstrap';
import { ErrorMessage } from 'formik';

const FormikCheckboxField = ({ field: { ...fields }, form: { touched, errors }, ...props }) => {
  const { id, label, isError = true } = props;
  const { name } = fields;

  return (
    <div>
      <Label for={id} className="radio-btn">
        <div>
          <Input {...props} {...fields} invalid={Boolean(touched[name] && errors[name])} />
        </div>
        <Label className="mb-0 ps-0 font-size-14 mt-1" htmlFor={id}>
          {label}
        </Label>
      </Label>
      {isError && (
        <ErrorMessage name={name} render={(msg) => <div className="error-message">{msg}</div>} />
      )}
    </div>
  );
};
export default FormikCheckboxField;
