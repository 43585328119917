import React from 'react';

const CombineErrorMessage = ({ children }) => {
  return (
    <div className="error-box mb-4">
      <span className="font-size-13">{children}</span>
    </div>
  );
};

export default CombineErrorMessage;
