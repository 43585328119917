import React, { useEffect, useState } from 'react';
import googleConfig from 'config/googleConfig';
import { useFormikContext, ErrorMessage } from 'formik';
import { Label } from 'reactstrap';

let onAutoComplete;

const StreetAddress = ({ name, label, isError = true, fillAddress, isLatLng = false }) => {
  const [hasError, setHasError] = useState(false);
  const { values, setFieldValue, errors, touched, handleBlur, handleChange } = useFormikContext();

  useEffect(() => {
    onLoadAutoComplete();
  }, [name]);

  useEffect(() => {
    const setError = checkIfError();
    setHasError(setError);
  }, [errors, touched]);

  const checkIfError = () => {
    return errors[name] && touched[name];
  };

  const onLoadAutoComplete = () => {
    let address1Field = document.querySelector('#streetAddressAutoComplete');
    googleConfig
      .load()
      .then((google) => {
        google.maps.event.addDomListener(address1Field, 'keydown', (event) => {
          if (event.code === 'Enter') {
            event.preventDefault();
          }
        });
        onAutoComplete = new google.maps.places.Autocomplete(address1Field, {
          componentRestrictions: { country: ['ca'] },
          fields: ['geometry', 'formatted_address', 'address_components'],
          types: ['address']
        });
        onAutoComplete.addListener('place_changed', getAddress);
      })
      .catch((e) => {
        console.log(e, 'autocomplete error');
      });
  };

  const getPostalCode = (name) => {
    const splitName = name.split(' ');
    if (splitName.length === 2) {
      return `${splitName[0]} ${splitName[1]}`;
    }
    return name;
  };

  const getAddress = () => {
    const place = onAutoComplete.getPlace();
    if (!place.geometry) {
      return 'Please select place';
    } else {
      const str = place.formatted_address;
      const splitStreetAdd = str.split(',');
      setFieldValue(name, splitStreetAdd[0]);
      const {
        geometry: { location }
      } = place;
      if (isLatLng) {
        setFieldValue('latitude', `${location.lat()}`);
        setFieldValue('longitude', `${location.lng()}`);
      }
    }

    if (fillAddress) {
      const { country, province, city, postalCode } = fillAddress;
      let newCity = null;
      let containSubLocality = false;
      let newPostalCode = null;

      for (const component of place.address_components) {
        // @ts-ignore remove once typings fixed
        const componentType = component.types[0];

        switch (componentType) {
          case 'postal_code':
            let postalCodeDigit = getPostalCode(component.long_name);
            if (postalCode) {
              newPostalCode = postalCodeDigit;
            }
            break;

          case 'locality':
            let locality = component.long_name;
            if (city && !containSubLocality) {
              newCity = locality;
            }
            break;
          case 'sublocality_level_1':
            if (city) {
              containSubLocality = true;
              newCity = component.long_name;
            }
            break;
          case 'administrative_area_level_1': {
            let stateName = component.long_name;
            if (province) setFieldValue(province, stateName);
            break;
          }
          case 'country':
            let countryName = component.long_name;
            if (country) {
              setTimeout(() => {
                setFieldValue(country, countryName);
              }, 100);
            }

            break;
        }
      }
      if (newPostalCode) {
        setTimeout(() => setFieldValue(postalCode, newPostalCode), 10);
      }
      if (newCity) {
        setTimeout(() => setFieldValue(city, newCity), 40);
      }
    }
  };

  return (
    <div>
      <Label>{label}</Label>
      <input
        type="text"
        name={name}
        id="streetAddressAutoComplete"
        placeholder=""
        className={`form-control-lg form-control ${hasError ? 'is-invalid' : ''}`}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values[name]}
      />
      {isError && (
        <ErrorMessage
          name={name}
          render={(msg) => {
            return <div className="text-danger font-size-14">{msg}</div>;
          }}
        />
      )}
    </div>
  );
};

export default StreetAddress;
