import { ApolloClient, createHttpLink, InMemoryCache, ApolloLink, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { getToken, onLogOut } from 'utils/UserDetails';

// const wsLink = new WebSocketLink({
//   uri: process.env.REACT_APP_WS_ENDPOINT,
//   options: {
//     reconnect: true
//   }
// });

const wsLink = new GraphQLWsLink(
  createClient({
    url: process.env.REACT_APP_WS_ENDPOINT,
    options: {
      reconnect: true
    }
  })
);

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, extensions }) => {
      if (message.includes('Context creation failed')) {
        onLogOut();
        window.location.reload();
      }
    });
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_BASE_URL
});

const cache = new InMemoryCache();

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({
    headers: {
      authorization: getToken() || null
    }
  });
  return forward(operation);
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  ApolloLink.from([errorLink, authMiddleware, httpLink])
);

const client = new ApolloClient({
  cache,
  link,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only'
    }
  }
});

export default client;
