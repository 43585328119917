import React, { useEffect, useState } from 'react';
import ProgressBar from 'components/ProgressBar';
import { Button, Card, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

const MinioFileUpload = ({ signedURL = '', file, onClose, onCancel = () => '' }) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    if (signedURL) {
      onUploadFile();
    }
  }, []);

  const onUploadFile = () => {
    try {
      const req = new XMLHttpRequest();
      req.open('PUT', signedURL, true);
      req.onload = () => onClose();
      req.upload.onprogress = (progressEvent) => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setProgress(percentCompleted);
      };
      req.send(file);
    } catch (err) {
      toast.error(err);
    }
  };

  return (
    <Card className="mb-4 bg-light">
      <CardBody className="pt-3">
        <div className="text-end">
          <FontAwesomeIcon
            onClick={onCancel}
            icon={faClose}
            className="text-dark font-size-18 cursor-pointer"
          />
        </div>
        <div className="d-flex">
          <h5 className="text-muted">{file?.name}</h5>
        </div>
        <div className="d-flex align-items-center">
          <ProgressBar value={progress} />
        </div>
      </CardBody>
    </Card>
  );
};

export default MinioFileUpload;
