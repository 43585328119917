import React, { memo, useContext, useState } from 'react';
import { Button, Navbar, NavbarBrand } from 'reactstrap';
import NavMenu from './component/NavMenu';
import { RoleContext } from 'context/Role';
import { AGENT_KEY, APPROVED_KEY, PRINCIPAL_BROKER, SUPER_ADMIN } from 'constant';
import { useLocation } from 'react-router-dom';
import { UserDetailsContext } from 'context/UserDetail';
import { BiMessageSquareDetail } from 'react-icons/bi';
import menuBurger from 'assets/icons/menu-burger.svg';
import mobileLogo from 'assets/icons/mobile-logo.svg';
import { useMediaQuery } from 'react-responsive';
import { isTabBrakePoint } from 'utils/responsiveBrakePoints';
import Notification from './component/Notification';
import CreateOppMenu from './component/CreateOppMenu';

function Navigation({ onClickMenu }) {
  const { roleTitle } = useContext(RoleContext);
  const { pathname } = useLocation();
  const userDetails = useContext(UserDetailsContext);
  const [isActive, setActive] = useState('Location');

  const isTab = useMediaQuery({ query: `(${isTabBrakePoint})` });

  let tabList = ['Location', 'Mortgage Request', 'Applicants', 'Guarantors'];

  return (
    <div>
      <Navbar className={isTab ? 'px-0' : 'px-3'}>
        <NavbarBrand>
          {isTab ? (
            <>
              <Button color="link" className="py-0 ps-0" onClick={() => onClickMenu(true)}>
                <img src={menuBurger} />
              </Button>
              <img src={mobileLogo} />
            </>
          ) : null}
        </NavbarBrand>
        {/* {tabView()} */}
        <div className="d-flex align-items-center">
          {roleTitle === SUPER_ADMIN && pathname === '/dashboard' ? (
            <div className="d-flex align-items-center">
              <BiMessageSquareDetail className="text-muted ms-4" size={24} />
            </div>
          ) : null}
          {(roleTitle === AGENT_KEY || roleTitle === PRINCIPAL_BROKER) &&
          userDetails?.approvedStatus === APPROVED_KEY ? (
            <CreateOppMenu />
          ) : null}
          <Notification />

          <NavMenu userDetails={userDetails} />
        </div>
      </Navbar>
    </div>
  );
}

export default memo(Navigation);
