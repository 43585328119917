import React from 'react';
import { Spinner } from 'reactstrap';
const PageLoader = ({ hideHeight = false, fullPageHeight = false }) => {
  return (
    <div
      className={`${hideHeight ? '' : 'h-100'} ${
        fullPageHeight ? 'vh-100' : ''
      } d-flex justify-content-center align-items-center`}>
      <Spinner
        color="primary"
        style={{
          height: '3rem',
          width: '3rem'
        }}>
        Loading...
      </Spinner>
    </div>
  );
};

export default PageLoader;
