import React from 'react';
import { Label, Button } from 'reactstrap';
import PhoneInput from 'react-phone-number-input';
import { BsCheck2 } from 'react-icons/bs';

const CustomPhoneInput = ({
  field: { ...fields },
  form: { touched, errors, setFieldValue, setFieldTouched, placeholder },
  required,
  isrequiredlabel,
  isoptionallabel,
  disabled = false,
  mb,
  isError = true,
  isVerifiedValue,
  isCorrectValue,
  openCloseVerifyModal,
  ...rest
}) => {
  const { name, value } = fields;
  const { id, label } = rest;

  const handleChange = (phoneValues) => {
    setFieldValue(name, phoneValues, true);
  };

  const handleBlur = () => {
    setFieldTouched(name, true, true);
  };

  return (
    <div>
      <div className={mb ? 'mb-1' : 'mb-3'}>
        {label && (
          <Label for={id} className="label-color w-100">
            <div className="d-flex justify-content-between align-items-center">
              {label}
              {isrequiredlabel ? <span className="text-muted font-size-14">Required</span> : null}
              {isoptionallabel ? <span className="text-muted font-size-14">Optional</span> : null}
            </div>
          </Label>
        )}
        <div className="position-relative">
          <PhoneInput
            defaultCountry="CA"
            international
            placeholder="Enter phone number"
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disabled}
            {...rest}
            className={`form-control-lg form-control ${
              errors[name] && touched[name] ? 'is-invalid' : ''
            }`}
          />
          {isCorrectValue && (
            <Button
              color="link"
              type="button"
              className="position-absolute verify-btn"
              onClick={openCloseVerifyModal}>
              Verify
            </Button>
          )}
          {isVerifiedValue && (
            <BsCheck2
              color="#0099FF"
              size={20}
              className="position-absolute"
              style={{ top: '13px', right: '10px' }}
            />
          )}
        </div>
        {isError && errors[name] && touched[name] && (
          <div className="error-message">{errors[name]}</div>
        )}
      </div>
    </div>
  );
};
export default CustomPhoneInput;
