import React, { useState, useEffect } from 'react';
import bellIcon from 'assets/logos/bell.svg';
import { isEmpty, cloneDeep } from 'lodash';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
  UncontrolledDropdown
} from 'reactstrap';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import Avatar from 'components/Avatar';
import {
  GET_NOTIFICATION,
  UPDATE_NOTIFICATION_STATUS,
  NOTIFICATION_SUBSCRIPTION,
  UDATE_NOTIFICATION_STATUS_BY_ID
} from './GraphQL';
import { useMutation, useQuery } from '@apollo/client';
import { getNotification } from './Helper';
import { getUser } from 'utils/UserDetails';
import PageLoader from 'components/PageLoader';

const Notification = () => {
  const user = getUser();
  const [isOpen, setIsOpen] = useState(false);
  const isTab = useMediaQuery({ query: `(min-width: 655px)` });

  const { data, subscribeToMore } = useQuery(GET_NOTIFICATION, {
    variables: {
      isRead: false
    }
  });
  const [updateNotification] = useMutation(UPDATE_NOTIFICATION_STATUS);

  const notificationCount = data?.getNotifications?.count ? data?.getNotifications?.count : 0;

  useEffect(() => {
    const unsubNotifications = subscribeNotifications();
    return () => {
      unsubNotifications();
    };
  }, []);

  const subscribeNotifications = () => {
    return subscribeToMore({
      document: NOTIFICATION_SUBSCRIPTION,
      variables: {
        userId: parseInt(user.id)
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!prev || isEmpty(prev)) return;
        if (!subscriptionData.data) return prev;

        const { notificationSubscription } = subscriptionData.data;
        const newData = cloneDeep(prev);
        const { count } = notificationSubscription;

        if (typeof count === 'number') {
          newData.getNotifications.count = count;
        }
        return newData;
      }
    });
  };

  const updateNotificationStatus = async () => {
    try {
      await updateNotification({
        variables: {
          isOpen: true,
          isRead: false
        }
      });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const toggleNotification = () => {
    setIsOpen((pre) => {
      if (!pre) {
        updateNotificationStatus();
        return true;
      } else {
        return !pre;
      }
    });
  };

  return (
    <Dropdown isOpen={isOpen} toggle={toggleNotification}>
      <DropdownToggle className="profile-toggle" color="link">
        <span className="px-4 position-relative" onClick={() => setIsOpen(false)}>
          {notificationCount > 0 && (
            <span className="notification-num">
              <Avatar
                size={16}
                fontsize={10}
                backgroundcolour="#0099FF"
                textcolour="#fff"
                fname={`${notificationCount}`}
              />
            </span>
          )}
          <img src={bellIcon} alt="bell-icon" height={20} width={20} />
        </span>
      </DropdownToggle>
      <DropdownMenu className={`notification border-0 ${isTab ? 'inset-position' : ''}`}>
        <DropdownItem header className="p-0">
          <span className="heading-title">Notifications</span>
        </DropdownItem>
        <DropdownItem header className="p-0">
          {isOpen && <NotificationList />}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

const NotificationList = () => {
  const [activeTab, setActiveTab] = useState('1');
  const [isRead, setIsRead] = useState(null);
  const navigate = useNavigate();
  const [updateNotification, { loading: markReadLoading }] = useMutation(
    UPDATE_NOTIFICATION_STATUS
  );
  const [notificationUpdateById] = useMutation(UDATE_NOTIFICATION_STATUS_BY_ID);
  const { data, loading, refetch } = useQuery(GET_NOTIFICATION, {
    variables: {
      offset: 0,
      limit: 50,
      isRead
    }
  });

  const notifications = data?.getNotifications?.notifications
    ? data?.getNotifications?.notifications
    : [];

  const onUpdateNotificationStatus = async (id) => {
    try {
      await notificationUpdateById({
        variables: {
          isRead: true,
          updateNotificationStatusByIdId: id
        }
      });
      refetch();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleActiveTab = (tab) => {
    setActiveTab(tab);
  };

  const markReadFilter = notifications.filter((item) => item.isRead === false);

  const onChangeRoute = (route) => {
    onUpdateNotificationStatus(route.id);
    if (route.redirectRoute && route.redirectRoute !== '/') {
      const randomString = Math.random().toString(36).substring(2, 12);
      if (route.redirectRoute.includes('?')) {
        const redirectLink = `${route.redirectRoute}&notificationId=${randomString}`;
        navigate(redirectLink);
      } else {
        const redirectLink = `${route.redirectRoute}?notificationId=${randomString}`;
        navigate(redirectLink);
      }
    }
  };

  const renderNotification = () => {
    if (loading) {
      return (
        <div className="mt-4">
          <PageLoader />
        </div>
      );
    }
    if (!notifications.length && !loading) {
      return <p className="text-center font-size-16 mt-3">No notification</p>;
    }
    return notifications?.map((notify) => (
      <div
        className="d-flex justify-content-between list-item cursor-pointer"
        onClick={() => onChangeRoute(notify)}
        key={notify.id}>
        <div className="d-flex">
          <span className="n-dot">
            <Avatar size={12} backgroundcolour={!notify.isRead ? '#0099FF' : '#ffffff'} />
          </span>
          {getNotification(notify)}
        </div>
        <span className="time">{moment(notify.createdAt).format('ll')}</span>
      </div>
    ));
  };

  const markAllRead = async () => {
    try {
      const { data } = await updateNotification({
        variables: {
          isRead: true,
          isOpen: false
        }
      });
      refetch();
      toast.success(data?.updateNotificationStatus?.message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onClickTab = (tabNum, value) => {
    handleActiveTab(tabNum);
    setIsRead(value);
  };

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            onClick={() => onClickTab('1', null)}
            className={activeTab === '1' ? 'active' : ''}>
            All
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            onClick={() => onClickTab('2', false)}
            className={activeTab === '2' ? 'active' : ''}>
            Unread
          </NavLink>
        </NavItem>
        {markReadFilter.length ? (
          <Button
            disabled={markReadLoading}
            color="link"
            className="mark-as-read"
            onClick={markAllRead}>
            <span className="font-size-16">Mark all as read</span>
          </Button>
        ) : null}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">{renderNotification()}</TabPane>
        <TabPane tabId="2">{renderNotification()}</TabPane>
      </TabContent>
    </div>
  );
};

export default Notification;
