const ONBOARDING_DATA = '';
const USER = '';
const LOG_IN = 'Log In';
const SIGN_UP = 'Sign Up';
const INVESTOR_KEY = 'Investor';
const AGENT_KEY = 'Agent';
const MORTGAGE_ADMIN = 'Mortgage Admin';
const PRINCIPAL_BROKER = 'Principal Broker';
const SUPER_ADMIN = 'Super Admin';

//Approve Section Keys
const PERSONAL_INFO_KEY = 'personal_information';
const CUSTOM_URL_KEY = 'custom_url';
const E_SIGN_KEY = 'e_sign';
const ACCOUNT_KEY = 'account';
const BUSINESS_INFO_KEY = 'business_info';
const BROKERAGE_DETAILS_KEY = 'brokerage_details';
const LENDING_CRITERIA_KEY = 'lending_criteria';
const MORTGAGE_ADMIN_KEY = 'mortgage_admin';
const FINAL_APPROVAL_KEY = 'final_approval';
const FILOGIX_INTEGRATION_KEY = 'filogix_integration_approval';

const REJECTED_KEY = 'rejected';
const APPROVED_KEY = 'approved';
const PENDING_KEY = 'pending';

const AUTH_MODE_LOGIN = 'login';
const AUTH_MODE_SIGNUP = 'signup';

const OPPORTUNITY_KEY = 'opportunity';

//Request and Invite type
const REQUEST_TYPE = 'request';
const INVITE_TYPE = 'invite';
const ACCEPT_TYPE = 'accept';
const REJECT_TYPE = 'reject';
const NOT_REQUESTED = 'not-requested';
const CONNECTED = 'connected';
const ONBOARDING_KEY = 'onboarding';
const ACCEPTED_KEY = 'accepted';

const OPENFUND_SIGNUP_URL = 'https://www.openfund.ca/signup';

//Roles KEY
const BROKER_ROLE_KEY = 'broker-agent';
const PB_ROLE_KEY = 'brokerage';
const INVESTOR_ROLE_KEY = 'investor';
const MA_ROLE_KEY = 'mac';
const SUPER_ADMIN_ROLE = 'super_admin';

//Role ID
const PB_ID = 6;
const INVESTOR_ID = 3;
const MA_ID = 5;
const BROKER_ID = 4;
const SUPER_ADMIN_ID = 1;

const REDACTED_TYPE = 'Redacted';
const REQUIRED = 'required';

const COUNTRIES = ['Canada'];

const PROVINCES = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Northwest Territories',
  'Nova Scotia',
  'Nunavut',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Yukon Territory'
];

export {
  INVESTOR_ID,
  BROKER_ID,
  MA_ID,
  //Roles Keys
  BROKER_ROLE_KEY,
  PB_ROLE_KEY,
  INVESTOR_ROLE_KEY,
  MA_ROLE_KEY,
  ONBOARDING_DATA,
  ONBOARDING_KEY,
  USER,
  LOG_IN,
  SIGN_UP,
  INVESTOR_KEY,
  AGENT_KEY,
  MORTGAGE_ADMIN,
  PRINCIPAL_BROKER,
  PERSONAL_INFO_KEY,
  CUSTOM_URL_KEY,
  E_SIGN_KEY,
  ACCOUNT_KEY,
  BUSINESS_INFO_KEY,
  BROKERAGE_DETAILS_KEY,
  LENDING_CRITERIA_KEY,
  MORTGAGE_ADMIN_KEY,
  FINAL_APPROVAL_KEY,
  REJECTED_KEY,
  APPROVED_KEY,
  PENDING_KEY,
  SUPER_ADMIN,
  OPPORTUNITY_KEY,
  AUTH_MODE_LOGIN,
  AUTH_MODE_SIGNUP,
  REQUEST_TYPE,
  INVITE_TYPE,
  OPENFUND_SIGNUP_URL,
  ACCEPT_TYPE,
  REJECT_TYPE,
  PB_ID,
  NOT_REQUESTED,
  REDACTED_TYPE,
  REQUIRED,
  SUPER_ADMIN_ID,
  CONNECTED,
  ACCEPTED_KEY,
  SUPER_ADMIN_ROLE,
  FILOGIX_INTEGRATION_KEY,
  COUNTRIES,
  PROVINCES
};
