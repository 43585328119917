import React from 'react';
import { Input, Label } from 'reactstrap';
import { ErrorMessage } from 'formik';

const FormikRadioField = ({
  field: { ...fields },
  form: { touched, errors, setFieldValue },
  isStringOptions,
  ...props
}) => {
  const { id, label, isError = true } = props;
  const { name } = fields;

  const handleChange = (e) => {
    if (isStringOptions) {
      const value = e.target.value;
      setFieldValue(name, value, true);
    } else {
      const isTrueSet = e.target.value === 'true';
      setFieldValue(name, isTrueSet, true);
    }
  };

  return (
    <div className="radio-btn-2">
      <label htmlFor={id} className="ps-0 font-size-14 d-flex">
        <div>
          <Input
            {...props}
            {...fields}
            onChange={handleChange}
            className={`mr-1 ${touched[name] && errors[name] ? 'is-invalid' : ''} `}
            invalid={Boolean(touched[name] && errors[name])}
          />
        </div>
        <span className="font-size-14 ps-2" style={{ color: '#81838A' }}>
          {label}
        </span>
      </label>
      {isError && (
        <ErrorMessage name={name} render={(msg) => <div className="error-message">{msg}</div>} />
      )}
    </div>
  );
};
export default FormikRadioField;
