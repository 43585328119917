import React from 'react';
import { Input, Label } from 'reactstrap';
import { ErrorMessage } from 'formik';

const FormikSelectField = ({
  field,
  form: { errors, touched },
  disabled = false,
  label,
  flag,
  ...props
}) => {
  const {
    id,
    options,
    defaultoption,
    keys,
    isRequiredLabel,
    isoptionallabel,
    isError = true
  } = props;
  const { name } = field;
  return (
    <>
      {label && (
        <Label for={id} className="label-color w-100">
          <div className="d-flex justify-content-between align-items-center">
            {label}
            {isRequiredLabel ? <span className="text-muted font-size-14">Required</span> : null}
            {isoptionallabel ? <span className="text-muted font-size-14">Optional</span> : null}
          </div>
        </Label>
      )}

      <Input
        id={id}
        {...field}
        {...props}
        type="select"
        invalid={Boolean(touched[name] && errors[name])}
        disabled={disabled}>
        <option value="" className="d-none">
          {defaultoption}
        </option>
        {options.map((option) => {
          if (keys) {
            // eslint-disable-next-line no-shadow
            const { id, label } = keys;
            return (
              <option value={option[id]} key={`${option[id]}_${option[label]}`}>
                {option[label]}
              </option>
            );
          }
          return (
            <option value={option} key={`option_${option}`}>
              {flag ? `<img src={googleImage}` : option}
            </option>
          );
        })}
      </Input>
      {isError && (
        <ErrorMessage
          name={name}
          render={(msg) => <div className="error-message position-absolute">{msg}</div>}
        />
      )}
    </>
  );
};

export default FormikSelectField;
