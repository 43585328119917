import React from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';
import { isEmpty } from 'lodash';

const FormikReactSelect = ({
  field: { name },
  label,
  onChange,
  onSelect,
  errorStyle,
  style = {},
  ...rest
}) => {
  const onInputChange = (e) => {
    if (onChange) {
      onChange(e);
    }
  };

  const getBorderColor = () => {
    if (errorStyle) {
      return '#ea6666';
    }
    return isEmpty(style) ? '#BCBDC2' : '#EA6666';
  };

  return (
    <div>
      <Label className="label-color w-100">{label}</Label>
      <div>
        <Select
          name={name}
          placeholder=""
          onChange={onSelect}
          onInputChange={onInputChange}
          controlShouldRenderValue={true}
          components={{ IndicatorSeparator: () => null }}
          classNamePrefix="react-select"
          styles={{
            control: (base) => ({
              ...base,
              ...style,
              borderColor: errorStyle ? '#ea6666' : '#ced4da',
              '&:hover': {
                borderColor: getBorderColor()
              }
            })
          }}
          {...rest}
        />
      </div>
    </div>
  );
};

export default FormikReactSelect;
